<template>
  <div >
    <van-nav-bar
      title="实名认证"
      left-arrow
      :fixed="true"
      :placeholder="true"
      @click-left="onClickLeft"
    />
    <van-form @submit="onSubmit">
      <van-field
        v-model="from.username"
        name="username"
        label="真实姓名"
        clearable
        placeholder="请输入真实姓名"
        :rules="[{ required: true, message: '请输入真实姓名' }]"
      />
      <van-field
        v-model="from.idCard"
        name="idCard"
        label="身份证号"
        clearable
        placeholder="请输入身份证号"
        :rules="[{ required: true, message: '请填写身份证号' }]"
      />
      <div style="margin: 16px">
        <van-button round block type="success" native-type="submit"
          >提交</van-button
        >
      </div>
    </van-form>
  </div>
</template>

<script>
import { getUserInfo, certification } from "@/utils/api.js";
export default {
  name: "realName",
  data() {
    return {
      from: {
        username: "",
        idCard: "",
      },
    };
  },
  created() {
    // this.getUserInfo()
  },
  methods: {
    getUserInfo() {
      getUserInfo().then((res) => {
        if (res.code === 200) {
          this.from.username = res.data.user.username;
          this.from.idCard = res.data.user.idCard;
        }
      });
    },
    onSubmit() {
      certification(this.from).then((res) => {
        if (res.code === 200) {
          this.$toast.success(res.message);
          setTimeout(() => {
            this.$toast.clear();
            this.onClickLeft();
          }, 1500);
        } else {
          this.$toast.fail(res.message);
        }
      });
    },
    onClickLeft() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped></style>
